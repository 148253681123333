<template lang="pug">
.options-setting.flex.column.p-20.gap-20.overflow-overlay
  .table-container.fill
    table
      thead
        tr
          th.hour 時間長短(小時)
          th.price 價格設定
          th.op
      tbody
        tr(v-for="(opt, idx) in options", :key="idx")
          td.hour
            input(type="number", step="1", min="1", max="23", v-model="opt.hour")
          td.price.p-8
            input(type="number", min="0", v-model="opt.price")
          td.op
            md-icon.clickable(@click.native="removeOpt(idx)") delete
        tr
          td(colspan="3")
            dd-button(
              @click.native="addOpt") 新增
  dd-button(
    @click.native="save"
    type="primary") 儲存
</template>

<script>
import { mapMutations } from 'vuex';
import { getOptions, updateOptions } from '@/api/reserve';

export default {
  name: 'fish-setting',
  data() {
    return {
      options: [],
    };
  },
  methods: {
    ...mapMutations(['setHeaderTitle']),
    removeOpt(idx) {
      this.options.splice(idx, 1);
      this.$forceUpdate();
    },
    addOpt() {
      const maxHour = this.options.reduce((ret, opt) => {
        const hour = parseInt(opt.hour, 10);
        if (hour > ret) {
          return hour;
        }
        return ret;
      }, 0);
      this.options.push({
        hour: maxHour + 1,
        price: 0,
      });
    },
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadOptions();
      });
    },
    async loadOptions() {
      const rsp = await getOptions();
      const opts = (rsp.data || []).map((opt) => ({
        hour: parseInt(opt.duration / 60, 10),
        price: opt.price,
      }));
      opts.sort((opt1, opt2) => opt1.hour - opt2.hour);
      this.options = opts;
    },
    save() {
      this.$execWithLoading(async () => {
        const data = this.options.map((opt) => ({
          duration: parseInt(opt.hour, 10) * 60,
          price: parseInt(opt.price, 10),
        }));
        await updateOptions(data);
        await this.loadOptions();
        this.$showSuccess('更新成功');
      });
    },
  },
  mounted() {
    this.setHeaderTitle('釣魚訂單設定');
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.options-setting {
  table {
    input {
      width: 100%;
      height: 100%;
      font-size: 20px;
      border: 1px solid #ccc;
      text-align: center;
      padding: 8px;
    }
    .hour {
      width: 40%;
      padding: 0;
    }
    .price {
      width: 40%;
    }
    .op {
      width: 10%;
      i {
        color: #F42C04;
      }
    }
  }
}
</style>
