import constant from '@/data/constant';
import instance from './base';

export async function getReserves(start, end) {
  const rsp = await instance.post('/posfishing/reserves/list', {
    start_from: start,
    start_to: end,
    exclude: [constant.orderStatus.ReserveStatusReserved],
  });
  return rsp.data;
}
export async function getReservedReserves(start, end) {
  const rsp = await instance.post('/posfishing/reserves/list', {
    start_from: start,
    start_to: end,
    include: [constant.orderStatus.ReserveStatusReserved],
  });
  return rsp.data;
}

export async function addReserve(start, end, count, price, notes, payment, source) {
  const rsp = await instance.post('/posfishing/reserve/', {
    start,
    end,
    count,
    status: 1,
    price,
    notes,
    payment,
    continue_source: source,
  });
  return rsp.data;
}

export async function getReserve(id) {
  const rsp = await instance.get(`/posfishing/reserve/${id}`);
  return rsp.data;
}

export async function getOptions() {
  const rsp = await instance.get('/posfishing/setting/options');
  return rsp.data;
}

export async function updateOptions(options) {
  const rsp = await instance.put('/posfishing/setting/options', options);
  return rsp.data;
}

export async function addReserveNote(id, note) {
  const rsp = await instance.post(`/posfishing/reserve/${id}/note`, note);
  return rsp.data;
}

export async function updateStatus(id, status) {
  const rsp = await instance.put(`/posfishing/reserve/${id}/status`, {
    status,
  });
  return rsp.data;
}

export async function getBufferTimes() {
  const rsp = await instance.get('/posfishing/setting/buffer-times');
  return rsp.data;
}

export async function updateBufferTimes(value) {
  const rsp = await instance.put('/posfishing/setting/buffer-times', value);
  return rsp.data;
}

export async function saveReservePayment(id, amount) {
  const rsp = await instance.put(`/posfishing/reserve/${id}/payment`, { amount });
  return rsp.data;
}
