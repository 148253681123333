const orderStatus = {
  ReserveStatusInit: 1,
  ReserveStatusFinish: 2,
  ReserveStatusCancel: 3,
  ReserveStatusPause: 4,
  ReserveStatusReserved: 5,
};

const paidStatus = {
  PaymentStatusInit: 1,
  PaymentStatusPartial: 2,
  PaymentStatusPaid: 3,
  PaymentStatusNeedChange: 4,
  PaymentStatusCancel: 5,
  PaymentStatusNoNeed: 6,
};

export default {
  paidStatus,
  paidStatusToText(payment) {
    const { status } = payment;
    const diff = payment.amount - payment.paid;
    switch (status) {
      case paidStatus.PaymentStatusInit:
        return `未付 ${diff} 元`;
      case paidStatus.PaymentStatusPartial:
        return `需再收 ${diff} 元`;
      case paidStatus.PaymentStatusPaid:
        return '已付';
      case paidStatus.PaymentStatusNeedChange:
        return `需要找零 ${-1 * diff} 元`;
      case paidStatus.PaymentStatusCancel:
        return '取消支付';
      case paidStatus.PaymentStatusNoNeed:
        return '無須支付';
      default:
        return '未知';
    }
  },
  orderStatus,
  orderStatusToText(status, shouldLeave) {
    if (status === orderStatus.ReserveStatusFinish) {
      return '已離場';
    } if (status === orderStatus.ReserveStatusPause) {
      return '已暫停';
    } if (status === orderStatus.ReserveStatusCancel) {
      return '已取消';
    } if (status === orderStatus.ReserveStatusReserved) {
      return '已保留';
    } if (shouldLeave) {
      return '應離場';
    }
    return '在場';
  },
};
